"use client";
  import {lazy, Suspense, useMemo} from 'react'
 import { __PYLON_ROUTER_INTERNALS_DO_NOT_USE, DevOverlay, PageProps} from '@getcronit/pylon/pages';
   const {Routes, Route, useParams, useSearchParams, useLocation} = __PYLON_ROUTER_INTERNALS_DO_NOT_USE
  const Page6d4bb9b4 = lazy(() => import('../pages/about/page.js'))
      
const Pageed2d47b5 = lazy(() => import('../pages/blog/[id]/page.js'))
      
const Page17cd9c5b = lazy(() => import('../pages/blog/page.js'))
      
const Page124168eb = lazy(() => import('../pages/contact/page.js'))
      
const Page290bbd0c = lazy(() => import('../pages/login/page.js'))
      
const Page675f9eea = lazy(() => import('../pages/page.js'))
      
const Page6a543899 = lazy(() => import('../pages/services/individual-webshops/page.js'))
      
const Page2c74b18e = lazy(() => import('../pages/services/web-applications/page.js'))
      
const Page8b343945 = lazy(() => import('../pages/services/webshops/page.js'))
      
const Page8e7b6dae = lazy(() => import('../pages/services/websites/page.js'))
      
const Page9578c1f0 = lazy(() => import('../pages/startup/page.js'))
      
  const RootLayout = lazy(() => import('../pages/layout.js'))
  

  const InjectPageProps: React.FC<{Page: React.FC, data: PageProps["data"]}> = ({Page, data}) => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const pageProps = useMemo(() => {
      return {
        data,
        params,
        searchParams: Object.fromEntries(searchParams.entries()),
        location: location.pathname
      }
    }, [data, params, searchParams, location.pathname])

    return <Page {...pageProps} />
  }
  
  const App: React.FC<{data: PageProps["data"]}> = (props) => {
    return (
      <DevOverlay>
        <RootLayout>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="stylesheet" href="/__pylon/static/app.css" precedence="high" />
              <Routes>
              <Route key={0} index={true} path="/about" element={
                  <InjectPageProps Page={Page6d4bb9b4 as React.FC} data={props.data} />
            } />
<Route key={1} index={false} path="/blog/:id" element={
                  <InjectPageProps Page={Pageed2d47b5 as React.FC} data={props.data} />
            } />
<Route key={2} index={false} path="/blog" element={
                  <InjectPageProps Page={Page17cd9c5b as React.FC} data={props.data} />
            } />
<Route key={3} index={false} path="/contact" element={
                  <InjectPageProps Page={Page124168eb as React.FC} data={props.data} />
            } />
<Route key={4} index={false} path="/login" element={
                  <InjectPageProps Page={Page290bbd0c as React.FC} data={props.data} />
            } />
<Route key={5} index={false} path="/" element={
                  <InjectPageProps Page={Page675f9eea as React.FC} data={props.data} />
            } />
<Route key={6} index={false} path="/services/individual-webshops" element={
                  <InjectPageProps Page={Page6a543899 as React.FC} data={props.data} />
            } />
<Route key={7} index={false} path="/services/web-applications" element={
                  <InjectPageProps Page={Page2c74b18e as React.FC} data={props.data} />
            } />
<Route key={8} index={false} path="/services/webshops" element={
                  <InjectPageProps Page={Page8b343945 as React.FC} data={props.data} />
            } />
<Route key={9} index={false} path="/services/websites" element={
                  <InjectPageProps Page={Page8e7b6dae as React.FC} data={props.data} />
            } />
<Route key={10} index={false} path="/startup" element={
                  <InjectPageProps Page={Page9578c1f0 as React.FC} data={props.data} />
            } />
          </Routes>
          </RootLayout>
        </DevOverlay>
    )
  }
  
  export default App;
    
          import {hydrateRoot} from 'react-dom/client'
          import * as client from './client'
          import { __PYLON_ROUTER_INTERNALS_DO_NOT_USE, DevOverlay, onCaughtErrorProd, onRecoverableErrorProd, onUncaughtErrorProd } from '@getcronit/pylon/pages';
          const {BrowserRouter} = __PYLON_ROUTER_INTERNALS_DO_NOT_USE
          import React, {useMemo} from 'react'

          const pylonData = window.__PYLON_DATA__

          const AppLoader = (props: {
            client: any
            pylonData: {
              cacheSnapshot?: any
            }
            App: React.FC<{
              data: PageProps['data']
            }>
            Router: React.FC<any>
            routerProps: any
          }) => {
            props.client.useHydrateCache({cacheSnapshot: props.pylonData.cacheSnapshot})
          
            const data = props.client.useQuery()
            const page = useMemo(() => {
              const page = (
                <props.App data={data} />
              )
          
              return page
            }, [props])
          
            return <props.Router {...props.routerProps}>{page}</props.Router>
          }

        
          

          hydrateRoot(
          document,
            <AppLoader Router={BrowserRouter} client={client} pylonData={pylonData} App={App} />, {
                  onCaughtError: onCaughtErrorProd,
      onRecoverableError: onRecoverableErrorProd,
      onUncaughtError: onUncaughtErrorProd,
            }
          )
          